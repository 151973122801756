import React, { useState, useEffect } from "react"
import emailjs from "emailjs-com"
import {
  ButtonStyled,
  CheckboxStyled,
  InputStyled,
  FieldStyled,
  SelecStyled,
  LabelStyled,
  InputLabelStyled,
  FormControlStyled,
  MenuItemStyled,
  FormHelperTextStyled,
} from "./formStyles"

import TextField from '@material-ui/core/TextField';

import PDF from "../pdf/AVENTURA-Klauzula-informacyjna-do-formularza-zapisu.pdf"
import AfterSend from "./elements/AfterSend"

import { FormControlLabel, FormGroup } from "@material-ui/core"

import MultipleSelect from "./elements/MultipleSelect"

const axios = require("axios")

export default function Contact(props) {
  const formFields = props.formFields.map(item => {
    return [item.name, ""]
  })

  const objectForm = Object.fromEntries(formFields)

  const [formData, setFormData] = useState(objectForm)
  const [message, setMessage] = useState("")
  const [thankYou, setThankYou] = useState("")
  const [popupContent, setPopupContent] = useState("")
  const [displayMessage, setDisplayMessage] = useState("")

  

  console.log('https://wordpress.aventura.turystyka.pl' + props.cfAPI +
  "")


  const selectFields = props.formFields.filter(function (field) {
    return field.inputType === "select"
  })
  const mapedSelectFields = selectFields.map(item => {
    return [item.name, ""]
  })
  const objectSelectForm = Object.fromEntries(mapedSelectFields)

  const [select, setSelect] = React.useState(objectSelectForm)

  const handleSelect = (event, value) => {
    const copySelect = { ...select }
    copySelect[event.target.name] = event.target.value
    setSelect(copySelect)
    const copyFormData = { ...formData }
    copyFormData[event.target.name] = event.target.value
    setFormData(copyFormData)
    
  }

  const handleInput = e => {
    const todaysDate = new Date().toJSON().slice(0,10).replace(/-/g,'.');
    const copyFormData = { ...formData }
    copyFormData[e.target.name] = e.target.value
    copyFormData['dataRejestracji'] = todaysDate
    setFormData(copyFormData)
  }

  const handleMultiplySelect = (value, field) => {
    const copyFormData = { ...formData }
    copyFormData[field] = value.join(", ")
    setFormData(copyFormData)
  }

  const callingFn = async e => {
    let data
    try {
      const response = await fetch(
      "https://v1.nocodeapi.com/" +
      props.apiUsser +
      "/google_sheets/" +
      props.APIKey +
      "?tabId=" +
      props.sheetID +
      "", {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      })
      const json = await response.json()
      const totalUsers = json["total"]
      // console.log('liczba zapisany uczestników: ' + totalUsers)
      if (totalUsers >= props.numberParticipants) {
        
        setThankYou("show");
        setPopupContent("Niestety nie mamy już wolnych miejsc. Jeśli jesteś zainteresowany tymi koloniami, prosimy o kontakt")
        setDisplayMessage("none")
      } else {
        setThankYou("none")
      }
    } catch (error) {
      console.error("Error:", error)
    }
    return data
  }
  

    useEffect(() => {
      callingFn();
    }, []);

    const sendData = async e => {
      e.persist()
      e.preventDefault()
  
      const templateParams = {
        from_email: formData.email,
        to_name: formData.name.concat(" ", formData.subname),
        message: formData.name,
        campName: props.nazwaKoloni,
      }


  
      // emailjs
      //   .send(
      //     "service_0xnhlfc",
      //     props.emailjstemplate,
      //     templateParams,
      //     "user_NV0QjxNFOUhOD5sBS4gON"
      //   )
      //   .then(
      //     function (response) {
      //       console.log("SUCCESS!", response.status, response.text)
      //     },
      //     function (error) {
      //       console.log("FAILED...", error)
      //       setThankYou("none")
      //     }
      //   )
  
      const testowy = Object.values(formData)
  
      try {
        const response = await fetch(
          "https://v1.nocodeapi.com/" +
            props.apiUsser +
            "/google_sheets/" +
            props.APIKey +
            "?tabId=" +
            props.sheetID +
            "",
          {
            method: "post",
            body: JSON.stringify([testowy]),
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(response => {
          if (!response.ok) {
            setPopupContent("Coś poszło nie tak, formularz nie został wysłany. Spróbuj jeszcze raz")
            setDisplayMessage("none")
            setThankYou("show")
          } else {
            // emailjs
            // .send(
            //   "service_0xnhlfc",
            //   props.emailjstemplate,
            //   templateParams,
            //   "user_NV0QjxNFOUhOD5sBS4gON"
            // )

            let contactFormData = new FormData()

            contactFormData.set("to_name", formData.name.concat(" ", formData.subname))
            contactFormData.set("from_email", formData.email)
            contactFormData.set("campName", props.nazwaKoloni)
            contactFormData.set("message", formData.name)


            axios({
              method: "POST",
              url: 'https://wordpress.aventura.turystyka.pl/' + props.cfAPI +
              "",
              data: contactFormData,
            }).then(
              response => {
                console.log('wysłano maila')
                console.log(templateParams)
              },
              error => {
                console.log(error)
              }
            )
            console.log("email wysłany")
            setThankYou("show")
            setPopupContent("Dziękujemy za rejestrację uczestnika na kolonie!")
          }
        })

        // setThankYou("show")
        // setPopupContent("Dziękujemy za rejestrację uczestnika na kolonie!")
      } catch (error) {
        // setMessage("Coś poszło nie tak... :(")
        // setThankYou("Error")
      }
    }

  const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <div className="RegisterForm" id={props.id} api={props.APIKey}>
      <form
        method="POST"
        data-netlify="true"
        className="input-form"
        id="contactForm"
        name="contact"
        required
        onSubmit={sendData}
        style={{ display: thankYou === "show" ? "none" : "flex" }}
      >
        {props.formFields.map(field => {
          switch (field.inputType) {
            case "input":
              return (
                <InputStyled
                  key={field.name}
                  name={field.name}
                  type={field.type}
                  placeholder={field.placeholder}
                  required
                  onChange={handleInput}
                  inputProps={{ pattern: field.zipPattern }}
                  label={field.placeholder}
                  variant="inline"
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />

              )

            case "label":
              return (
                <LabelStyled key={field.name}>
                  {field.label}
                  <InputStyled
                    name={field.name}
                    type={field.type}
                    placeholder={field.placeholder}
                    onChange={handleInput}
                    required
                  />
                </LabelStyled>
              )

            case "textarea":
              return (
                <FieldStyled
                  key={field.name}
                  name={field.name}
                  type={field.type}
                  placeholder={field.placeholder}
                  required={field.required}
                  onChange={handleInput}
                  multiline
                />
              )

            case "select":
              return (
                <FormControlStyled key={field.name} className={field.name}>
                  <InputLabelStyled id={field.name}>wybierz</InputLabelStyled>
                  <SelecStyled
                    labelId={field.name}
                    key={field.name}
                    name={field.name}
                    value={select[field.name]}
                    required
                    onChange={handleSelect}
                  >
                    {field.selectOptions.map(item => (
                      <MenuItemStyled
                        key={item.selectvalue}
                        value={item.selectvalue}
                      >
                        {item.selectvalue}
                      </MenuItemStyled>
                    ))}
                  </SelecStyled>
                  <FormHelperTextStyled>
                    {field.placeholder}
                  </FormHelperTextStyled>
                </FormControlStyled>
              )

            case "multipleSelect":
              return (
                <MultipleSelect
                  key={field.name}
                  options={field.multipleSelectOptions}
                  name={field.name}
                  placeholder={field.placeholder}
                  salectChange={value =>
                    handleMultiplySelect(value, (field = field.name))
                  }
                />
              )

            case "requiredField":
              return (
                <InputStyled
                  key={field.name}
                  name={field.name}
                  type={field.type}
                  placeholder={field.placeholder}
                  onChange={handleInput}
                  style={{
                    display:
                      formData[field.requiredfieldname] ===
                      field.requiredfieldvalue
                        ? "block"
                        : "none",
                  }}
                />
              )
              case "date":
                return (
                  
                  <TextField
                  key={field.name}
                  name={field.name}
                  label={field.placeholder}
                  type="date"
                  className="DataField"
                  onChange={handleInput}
                  required
                  InputLabelProps={{
                    shrink: false,
                  }}
                />
                )
            default:
              return (
                <InputStyled
                  key={field.name}
                  name={field.name}
                  type={field.type}
                  placeholder={field.placeholder}
                  required
                  onChange={handleInput}
                />
              )
          }
        })}


        <FormGroup>
          <FormControlLabel
            control={
              <CheckboxStyled name="checkedC" color="primary" required />
            }
            label="Oświadczam, że "
          />
          <div className="MuiFormControlLabel__label">
            {" "}
            zapoznałem się i akceptuję warunki przetwarzania danych osobowych,
            zgodnie z{" "}
            <a rel="noopener noreferrer" href={PDF} target="_blank">
              {" "}
              klauzulą informacyjną do formularza rejestracyjnego.
            </a>
          </div>
        </FormGroup>


        <ButtonStyled
          id="sendForm"
          variant="contained"
          color="primary"
          name="submit"
          type="submit"
        >
          Zapisz się!
        </ButtonStyled>
      </form>
      {message}
      <AfterSend 
      email={formData.email}
      message={displayMessage}
      messageContent={popupContent} 
      style={{ display: thankYou === "show" ? "flex" : "none" }}    
      />
    </div>
  )
}
