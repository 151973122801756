import React from "react"
import styled from "styled-components"
import {
  withStyles,
  Input,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Checkbox,
} from "@material-ui/core"

export const CheckboxStyled = withStyles({
  root: {
    color: "#82a0bc",
    "&$checked": {
      color: "#82a0bc",
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />)

export const ButtonStyled = withStyles(theme => ({
  root: {
    fontSize: "15px",
    letterSpacing: "0.2em",
    borderRadius: "5px",
    backgroundColor: "#7883c0",
    padding: "20px",
    lineHeight: "6px",
    fontFamily: `'Josefin Sans', sans-serif`,
    transition: "all 0.4s ease 0s",
    marginTop: "1rem",
    "&:hover": {
      background: "#241d50",
      letterSpacing: "0.35em",
      transition: "all 0.4s ease 0s",
    },
  },
}))(Button)

export const InputStyled = styled(Input)`
  font-family: ${props => props.theme.fonts.text} !important;
  margin: 0.5rem 0;
`

export const FieldStyled = styled(TextField)`
  font-family: ${props => props.theme.fonts.text} !important;
  margin: 0.5rem 0 !important;
`
export const SelecStyled = styled(Select)`
  font-family: ${props => props.theme.fonts.text} !important;
  margin: 0.5rem 0;
`
export const FormControlStyled = styled(FormControl)`
  font-family: ${props => props.theme.fonts.text} !important;
`
export const InputLabelStyled = styled(InputLabel)`
  font-family: ${props => props.theme.fonts.text} !important;
`
export const MenuItemStyled = styled(MenuItem)`
  font-family: ${props => props.theme.fonts.text} !important;
`
export const FormHelperTextStyled = styled(FormHelperText)`
  font-family: ${props => props.theme.fonts.text} !important;
`

export const LabelStyled = styled.label`
  color: ${props => props.theme.colors.primary};
  font-family: ${props => props.theme.fonts.text};
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  font-weight: 800;

  div {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
`
