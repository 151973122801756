import React from "react"
import Bon from "../elements/bon"

import Layout from "../../components/layout"
import CampTabs from "../elements/CampTabs"
import Swiper from "../elements/Slider"
import Contact from "../form"

import "./pagination.scss"
import "./postStyles.scss"

import {
  CampDate,
  CampDesc,
  CampImage,
  CampLayout,
  CampPrice,
  CampWrapperImage,
  CampWrapperInf,
  CampsTitle,
} from "./PostStyles"

import FormModal from "../modal"

const IndexPage = props => {
  console.log(props.pathContext)

  const { title } = props.pathContext
  const { ACF_kolonie } = props.pathContext
  const { featuredImage } = props.pathContext
  const ImageSVG = featuredImage.node.localFile.publicURL
  const TabsCamp = ACF_kolonie.zakladki

  console.log(TabsCamp)

  const FeatureImageSVG = () => {
    if (
      !featuredImage.node.localFile.childImageSharp &&
      featuredImage.node.localFile.extension === "svg"
    ) {
      return <img alt="featuredImage" src={ImageSVG} />
    } else {
      return (
        <img
          alt="zdjęcie kolonii"
          src={featuredImage.node.localFile.publicURL}
        />
      )
    }
  }
  const Image = () => {
    if (ACF_kolonie.galleryorsingle === "tak") {
      return (
        <CampImage>
          <Swiper slides={ACF_kolonie.gallery} />
        </CampImage>
      )
    } else {
      return <CampImage>{FeatureImageSVG()}</CampImage>
    }
  }

  return (
    <Layout>
      <CampLayout>
        <CampWrapperImage>
          {Image()}
          <CampWrapperInf>
            <CampsTitle>{title}</CampsTitle>
            <CampDate>
              termin: <span>{ACF_kolonie.termin}</span>
            </CampDate>
            <CampPrice>
              cena: <span>{ACF_kolonie.cena}</span>
            </CampPrice>
            <CampDesc
              dangerouslySetInnerHTML={{ __html: ACF_kolonie.shortDesc }}
            ></CampDesc>

            <FormModal title={title}>
              <Contact
                id={title}
                apiUsser={ACF_kolonie.apiUsser}
                APIKey={ACF_kolonie.apiKey}
                sheetID={ACF_kolonie.sheetId}
                numberParticipants={ACF_kolonie.numberPlaces}
                selectOptions={ACF_kolonie.stays}
                formFields={ACF_kolonie.formArea}
                nazwaKoloni={title}
                emailjstemplate={ACF_kolonie.emailjstemplate}
                cfAPI={ACF_kolonie.emailformapi}
              />
            </FormModal>
          </CampWrapperInf>
        </CampWrapperImage>
        <CampTabs acf={ACF_kolonie.zakladki} />
      </CampLayout>
    </Layout>
  )
}

export default IndexPage
