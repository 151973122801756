import styled from "styled-components"

export const CampsTitle = styled.h1`
  font-size: 2rem;
  font-family: ${props => props.theme.fonts.primary};
  font-weight: 800;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;

  @media ${props => props.theme.sizes.mobileM} {
    font-size: 3rem;
  }

  @media ${props => props.theme.sizes.tablet} {
    margin-bottom: 3rem;
  }
`
export const CampLayout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3.5% 2.5%;
  margin-top: 100px;
`

export const CampImage = styled.div`
  max-width: 100%;
  width: 100%;

  .swiper-container {
    margin-left: 10%;
    margin-right: 1%;
    margin-top: 5%;
  }
  .swiper-pagination-bullets {
    bottom: 0;
  }

  @media ${props => props.theme.sizes.tablet} {
    max-width: 50%;
  }
  .swiper-slide {
    height: 600px;

    padding-bottom: 40px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

export const CampWrapperImage = styled.div`
  display: flex;
  flex-direction: column;

  @media ${props => props.theme.sizes.tablet} {
    flex-direction: row;
  }
`
export const CampWrapperInf = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: ${props => props.theme.fonts.secondary};
  padding: 2% 0 10%;

  @media ${props => props.theme.sizes.tablet} {
    padding: 2%;
    max-width: 50%;
  }
`
export const CampDesc = styled.div`
  font-size: 1rem;
  margin: 1rem 0;

  @media ${props => props.theme.sizes.tablet} {
    margin: 1rem 0 0;

    p {
      margin-bottom: 1rem;
    }
  }
`
export const CampDate = styled.div`
  font-size: 1.2rem;

  span {
    font-size: 1.5rem;
    font-weight: 700;
    margin-left: 10px;
  }
`
export const CampPrice = styled.div`
  font-size: 1.2rem;

  span {
    font-size: 1.5rem;
    font-weight: 700;
    margin-left: 10px;
  }
`
