import AppBar from "@material-ui/core/AppBar"
import Box from "@material-ui/core/Box"
import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import { makeStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  }
}

const TabsWrapper = styled.div``

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,

    [theme.breakpoints.up("lg")]: {
      width: "100%",
      margin: "0 auto",
    },
  },
  appBar: {
    justifyContent: "space-evenly",
  },
  tabs: {
    borderRadius: "5px",
    backgroundColor: "#7883c0",
    color: "#fff",
  },
  tabSingle: {
    fontSize: "14px",
    lineHeight: "6px",
    fontFamily: `'Josefin Sans', sans-serif`,
    color: "#fff",
  },
  tabPanel: {
    fontFamily: `'Montserrat', sans-serif`,
    padding: "20px 0px",

    root: {
      // Some CSS
      padding: "10px",
    },
  },
}))

export default function CampTabs(props) {
  const ACF = props.acf
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  console.log(props.acf)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const TabPanels = ACF.map((tab, index) => (
    <TabPanel
      key={index}
      index={index}
      value={value}
      className={classes.tabPanel}
    >
      <div dangerouslySetInnerHTML={{ __html: tab.desc }} />

      {tab.dokumenty ? (
        <ul className="filesList">
          <h3 className="filesListTitle">Dokumenty do pobrania:</h3>
          {tab.dokumenty.map(file =>
            file.plik ?? console.log("null") ? (
              <li key={file.plik.title}>
                <a href={file.plik.localFile.publicURL} download>
                  {file.plik.title}
                </a>
              </li>
            ) : null
          )}
        </ul>
      ) : null}
    </TabPanel>
  ))

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default" className={classes.appBar}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          className={classes.tabs}
        >
          {ACF.map((tab, index) => (
            <Tab
              key={index}
              className={classes.tabSingle}
              index={tab.nazwaZakladki}
              label={tab.nazwaZakladki}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </AppBar>
      {TabPanels}
    </div>
  )
}
