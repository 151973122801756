import React from "react"
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Autoplay]);



export default props => {
  console.log(props.slides)
  const slides = props.slides

  const singleSlide = slides.map((item, index) => {
      if (item.singleimage) {
        console.log(item)
        return (
            <SwiperSlide key={index}><img className="sliderImage" alt="featuredImage" src={item.singleimage.localFile.publicURL} /></SwiperSlide>
        )
      }
  }
)
  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={0}
      autoplay={true}
      pagination={{ clickable: true }}
    >
    {singleSlide}
    </Swiper>
  )
}
