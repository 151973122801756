import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import Logo from "./logo"

const AfterSend = (props) => {
  console.log("aftersend")
  console.log(props)

    const ThankYouMessage = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .gatsby-image-wrapper {
            width: 200px !important;
            height: 100px !important;

            img {
                object-fit: contain !important;
            }
        }
    `
    const MessageTitle = styled.h3`
        font-family: ${props => props.theme.fonts.secondary};
        text-align: center;
        font-weight: 700;
        margin-top: 1rem;
    `
    const Message = styled.div`
    font-family: ${props => props.theme.fonts.text};
    text-align: center;
    margin-bottom: 1rem;

    bold {
      font-weight: 900;
      text-decoration: underline;
  
    }
    `
    const Link = styled.a`
        color: ${props => props.theme.colors.primary};
        text-decoration: none;
        font-weight: 700;
        font-family: ${props => props.theme.fonts.text};
        margin-bottom: 2rem;
    `

  return (
    <ThankYouMessage
        style={props.style}
    >
        <Logo/>
        <MessageTitle>{props.messageContent}</MessageTitle>
      
      <Message style={{display: props.message}}>
        Bardzo się cieszymy, że wybrali Państwo naszą ofertę! Rejestracja zostanie zaakceptowana po dokonaniu wpłaty zaliczki oraz dostarczeniu umowy wraz z kartą kwalifikacyjną. Na adres mailowy - <bold>{props.email}</bold> , została wysłana wiadomość z dalszą instrukcją oraz wszystkimi potrzebnymi dokumentami. W razie pytań zachęcamy do kontaktu: 
      </Message>
      <Link href="mailto:aventura.kontakt@gmail.com" target="_blanc">aventura.kontakt@gmail.com</Link>
    </ThankYouMessage>
  )
}

export default AfterSend
