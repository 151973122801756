import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import FormHelperText from "@material-ui/core/FormHelperText"



export default function MultipleSelect(props) {
  const MultipleSelectedOptions = []
  const SOptions = props.options.map(item => {

    MultipleSelectedOptions.push(item.option)
  })

  const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: 120,
      maxWidth: 300,
      fontFamily: `'Montserrat', sans-serif`,
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
      fontFamily: `'Montserrat', sans-serif`,
    },
    select: {
      fontFamily: `'Montserrat', sans-serif`,
    },
    placeholder : {
      fontFamily: `'Montserrat', sans-serif`,
    },
    label: {
      fontFamily: `'Montserrat', sans-serif`,
    },
    chip: {
      margin: 2,
      fontFamily: `'Montserrat', sans-serif`,
    },
    noLabel: {
      marginTop: theme.spacing(3),
      fontFamily: `'Montserrat', sans-serif`,
    }
  }));
  
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };
  
  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium
    };
  }
  const classes = useStyles();
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  console.log(personName)

  

  const handleChange = (event) => {
    setPersonName(event.target.value);
    console.log(event.target.value)
    props.salectChange(event.target.value)
    console.log(event)
  };

  console.log(props)

  return (
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-mutiple-chip-label" className={classes.label}>{props.name}</InputLabel>
        <Select
          labelId="demo-mutiple-chip-label"
          id="demo-mutiple-chip"
          multiple
          value={personName}
          onChange={handleChange}
          className={classes.select}
          input={<Input id="select-multiple-chip" />}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip key={value} label={value} className={classes.chip} />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {MultipleSelectedOptions.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, personName, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText className={classes.placeholder}>{props.placeholder}</FormHelperText>
      </FormControl>
  );
}
