import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Modal from "@material-ui/core/Modal"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"
import Button from "@material-ui/core/Button"
import styled from "styled-components"

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
    border: 'none',
    padding: theme.spacing(8, 4, 3),
    maxHeight: "90vh",
    overflowY: "auto",
    overflowX: "hidden",
    maxWidth: "90%",

    [theme.breakpoints.up("md")]: {
      maxWidth: "60%",
      padding: theme.spacing(8, 10, 3),
    },
    [theme.breakpoints.up("xl")]: {
      maxWidth: "800px",
      padding: theme.spacing(8, 10, 3),
    },
  },
  button: {
    fontSize: "14px",
    letterSpacing: "0.15em",
    borderRadius: "5px",
    backgroundColor: "#7883c0",
    padding: "20px",
    lineHeight: "6px",
    fontFamily: `'Josefin Sans', sans-serif`,
    transition: "all 0.4s ease 0s",
    "&:hover": {
      background: "#241d50",
      letterSpacing: "0.3em",
      transition: "all 0.4s ease 0s",
    },
  },
}))

export const ModalTitle = styled.h2`
  font-size: 1.8rem;
  font-family: ${props => props.theme.fonts.secondary};
  font-weight: 800;
  color: ${props => props.theme.colors.primary};
  text-align: center;
  margin-bottom: 1.5rem;

  @media ${props => props.theme.sizes.tablet} {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
`

export const ModalWrapper = styled.div``

export default function FormModal(props) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <ModalWrapper>
      <Button
        variant="contained"
        color="primary"
        type="button"
        onClick={handleOpen}
        className={classes.button}
      >
        Zapisz się na kolonie
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper + " FormModalBody"}>
            <ModalTitle id="transition-modal-title">{props.title}</ModalTitle>
            {props.children}
          </div>
        </Fade>
      </Modal>
    </ModalWrapper>
  )
}
